
import { computed, defineComponent, onMounted } from "vue"
import { useRouter } from "vue-router"
import { useStore } from "vuex"
import { productsCountCart, useCart, cart } from "@/services/useCart"

export default defineComponent({
	name: "SbCart",
	props: {
		color: {
			type: String,
			default: "white",
		},
		counterColor: {
			type: String,
			default: "#BB29BB",
		},
		fullMode: {
			type: Boolean,
			default: false,
		},
	},
	setup() {
		const store = useStore()
		const { getCart } = useCart()
		const user = store.getters["auth/getUser"]
		const router = useRouter()

		const showCart = computed(() => {
			return true
		})

		const gotToCart = () => {
			router.push({
				name: "Cart",
			})
		}

		onMounted(async () => {
			if (user) {
				if (!cart.value) await getCart()
			}
		})

		return {
			gotToCart,
			productsCountCart,
			showCart,
		}
	},
})
