
import { defineComponent, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { useRouter } from "vue-router"
import SbIcon from "@/components/icons/SbIcon.vue"

import { useProduct } from "@/services/useProduct"
import { useSearch, searchKey, query, limitResult } from '@/composables/useSearch'
import { useTerm } from '@/services/useTerm'

export default defineComponent({
	name: "SbMainSearch",
	components: { SbIcon },
	props: {
		placeholder: {
			type: String,
			default: "",
		},
		name: {
			type: String,
			default: "",
		},
		shadow: {
			type: Boolean,
			default: true,
		},
		focus: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['onSearching', 'onEnter', 'searchInstance', 'onClear', 'onBlur'],
	setup(props: any, { emit }) {
		const { searchProducts, products } = useProduct()
		const { isSearching, searchResults, handlerCleanSearch } = useSearch()
		const { saveTerm } = useTerm()
		const haveButtonClear = ref(false)
		const inputRef = ref()
		const router = useRouter()
		const debounceTime = 500;
		let debounceTimer: any;

		watch(searchKey, async (searchKeyNewValue) => {
			clearTimeout(debounceTimer);
			isSearching.value = true

			debounceTimer = setTimeout(async () => {
				if (searchKeyNewValue) {
					haveButtonClear.value = true
					query.value = `?search=${searchKeyNewValue}&limit=${limitResult.value}`

					await searchProducts(query.value)
					searchResults.value = products.value

					// Save search term
					saveTerm(searchKeyNewValue)

					router.replace({ name: "ViewResultsSearch", query: { search: searchKeyNewValue }})

				} else {
					searchResults.value = []
					haveButtonClear.value = false
				}

				isSearching.value = false
			}, debounceTime)
		})

		onBeforeUnmount(() => {
			clearTimeout(debounceTimer);
		});

		watch(
			() => props.focus,
			() => {
				setTimeout(() => {
					inputRef.value.focus()
				}, 500)
			}
		)

		const updateSearch = (event: any) => {
			searchKey.value = event.target.value

			if ( searchKey.value ) {
				emit('onSearching')
			}
		}

		onMounted(() => {
			emit("searchInstance", inputRef.value)
		})

		const onEnter = () => {
			if (searchKey.value) {
				emit("onEnter", searchKey.value)
			}
		}

		const onClear = () => {
			handlerCleanSearch()
			emit("onClear")
		}

		const handlerBlur = () => {
			emit("onBlur")
		}

		return {
			inputRef,
			searchKey,
			haveButtonClear,
			onEnter,
			onClear,
			handlerBlur,
			updateSearch,
		}
	},
})
