import { ref, watch } from "vue"
import { CartItem, ShippingRate } from "@/types"

export interface CartOrder {
	dispatchDate: string
	products: CartItem[]
	shippingRate?: ShippingRate
}

export function useCartOrders(products: Array<CartItem>) {
	const cartOrders = ref<CartOrder[]>([])

	const organizeOrders = () => {
		const ordersValue: Record<string, CartOrder> = {}

		const nonAnticipatedProducts = products.filter(
			product => !product.dispatchDate || !product.advancedPurchasing
		)

		if (nonAnticipatedProducts.length > 0) {
			if (!ordersValue["none"]) {
				ordersValue["none"] = {
					dispatchDate: "none",
					products: [],
				}
			}

			ordersValue["none"].products.push(...nonAnticipatedProducts)
		}

		const anticipatedProducts = products.filter(
			product => product.dispatchDate && product.advancedPurchasing
		)

		anticipatedProducts.sort((a, b) =>
			(a.dispatchDate as string).localeCompare(b.dispatchDate as string)
		)

		anticipatedProducts.forEach(product => {
			const dispatchDate = product.dispatchDate as string

			if (!ordersValue[dispatchDate]) {
				ordersValue[dispatchDate] = {
					dispatchDate,
					products: [],
				}
			}

			ordersValue[dispatchDate].products.push(product)
		})

		cartOrders.value = Object.values(ordersValue)
	}

	watch(products, () => organizeOrders())

	organizeOrders()

	return {
		cartOrders,
	}
}
