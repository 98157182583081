
import { defineComponent, ref } from "vue"
import { useStore } from "vuex"
// import SbIcon from "./icons/SbIcon.vue";

const __default__ = defineComponent({
	name: "SbLocationAddress",
	props: {
		color: {
			type: String,
			default: "#ffffff",
		},
		title: {
			type: String,
			default: "Entrega en:",
		},
		content: {
			type: String,
			default: "",
		},
		flexDirection: {
			type: String,
			default: "row"
		},
		alignItems: {
			type: String,
			default: "center"
		},
	},
	setup() {
		const store = useStore()
		const user: any = ref(store.getters["auth/getUser"])
		return {
			user,
		}
	},
	components: {
		// SbIcon
	},
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "8f489a86": (_ctx.flexDirection),
  "0ed6fbe4": (_ctx.alignItems)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__