import { AxiosRequestConfig } from "axios";
import { InterceptorRequestStrategy } from "@/types";
import store from "../../store/index";

/**
* Si el token es de un Seller, entonces configura el token, el warehouse y los datos del cliente seleccionado,
* sino solo configura el token y el warehouse.
*/
export class ConfigureCustomerDataSelectedBySeller implements InterceptorRequestStrategy {
    applyInterceptor(config: AxiosRequestConfig): AxiosRequestConfig {
        const token: string = store.getters["auth/getToken"];
        const warehouseId = store.getters["auth/getUser"].warehouseId;
        const reference = store.getters['getReference'];

        let userId = store.getters["auth/getUserId"]
        let tradeTypeId = store.getters["auth/getUser"].customer.trade_type;
        let userCityId = store.getters["auth/getUser"].customer.cityId
        let userStateId = store.getters["auth/getUser"].customer.stateId

        const customerConfiguredBySeller = store.getters["auth/getCustomerConfiguredBySeller"];


        if ( customerConfiguredBySeller ) {
            userId = customerConfiguredBySeller._id
            userCityId = customerConfiguredBySeller.cityId
            userStateId = customerConfiguredBySeller.stateId
            tradeTypeId = customerConfiguredBySeller.tradeTypeId
        }

        config.headers = { token };
        config.params = {
			...config.params,
			warehouse: warehouseId,
			reference,
			tradeTypeId,
			userId,
			userCityId,
			userStateId,
		}

        return config;
    }
}