import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7f214ee9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "select-viewer-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "select-viewer",
    style: _normalizeStyle('color:' + _ctx.color)
  }, [
    _createElementVNode("span", _hoisted_1, [
      _createTextVNode(_toDisplayString(_ctx.title) + "   ", 1),
      _createElementVNode("strong", {
        class: _normalizeClass(['select-viewer-content', _ctx.content ? 'purple' : 'gray'])
      }, _toDisplayString(_ctx.content || "Seleccionar cliente"), 3)
    ])
  ], 4))
}