import { RouteRecordRaw } from "vue-router"

export const manufacturerRoutes: Array<RouteRecordRaw> = [
	{
		path: "/home/manufacturers",
		name: "Manufacturers",
		meta: {
			requiresAuth: true,
			navigationTitle: "Marcas",
			pageTitle: "Compra por marcas",
		},
		component: () =>
			import("@/views/manufacturers/SbPageManufacturers.vue"),
	},
	{
		path: "/home/manufacturers/:manufacturerId/:manufacturerName",
		name: "Manufacturer",
		props: true,
		meta: {
			requiresAuth: true,
			navigationTitle: "Compra por marca",
			navigationSearch: true,
		},
		component: () =>
			import("@/views/manufacturers/SbPageManufacturerNavigation.vue"),
	},
]
