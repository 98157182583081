
import { defineComponent, ref, onMounted, computed } from "vue"
import { isPlatform, IonImg } from "@ionic/vue"
import SbLocationAddress from "@/components/SbLocationAddress.vue"
import SbMainSearch from "@/components/SbMainSearch.vue"
import SbCart from "@/components/cart/SbCart.vue"
import { useRouter } from "vue-router"
import { useStore } from "vuex"
import SbPromotionalHeader from "@/components/headers/SbPromotionalHeader.vue"
import SbButton from "../SbButton.vue"
import { ROLES } from "@/constants/roles"

const __default__ = defineComponent({
	name: "SbHeaderMobile",
	props: {
		showLocation: {
			type: Boolean,
			default: true,
		},
		showSearch: {
			type: Boolean,
			default: true,
		},
	},
	components: {
		SbLocationAddress,
		SbMainSearch,
		SbCart,
		SbPromotionalHeader,
		SbButton,
		IonImg
	},
	setup() {
		const store = useStore()
		const user: any = ref(null)
		const router = useRouter()
		const paddingTop = isPlatform("ios") ? "60px" : "0px"
		const locationAddressContent = ref("")
		const isGuest = computed(() => store.getters['auth/getRole'] === ROLES.GUEST)

		onMounted(async () => {
			const isLogged = await store.getters["auth/getIsLogged"]
			if (isLogged) {
				user.value = await store.getters["auth/getUser"]
				locationAddressContent.value = user.value.customer.city
			}
		})

		const inputSearchOn = () => {
			router.push({
				name: "ViewResultsSearch",
			})
		}

		return {
			paddingTop,
			user,
			inputSearchOn,
			locationAddressContent,
			isGuest
		}
	},
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "7e4d2dbd": (_ctx.paddingTop)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__