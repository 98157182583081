import { Product } from "@/types"

interface State {
	cartProducts: Array<Product>
}

const state = (): State => ({
	cartProducts: [],
})

const mutations = {
	setCartProducts(state: any, products: Array<Product> | []) {
		state.cartProducts = products
	},
}

const actions = {
	async getProductById({ state }: any, { id }: { id: string }) {
		return state.cartProducts.filter(
			(product: any) => product.productId === id
		)
	},
}

const getters = {
	cartProducts: (state: any) => state.cartProducts,
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}
