
import { computed, defineComponent } from "vue"
import { IonButton, IonButtons, IonToolbar } from "@ionic/vue"
import { useRoute, useRouter } from "vue-router"
import SbIcon from "@/components/icons/SbIcon.vue"
import SbMainSearch from "@/components/SbMainSearch.vue"
import SbHeader from "./SbHeader.vue"
import SbHeaderMobile from "./SbHeaderMobile.vue"
import SbCart from "../cart/SbCart.vue"
import { useAlert } from "@/services/useAlert"
import { isLoading, useCart, productsCountCart } from "@/services/useCart"
import SbLocationAddress from "../SbLocationAddress.vue"
import { useStore } from "vuex"

export default defineComponent({
	name: "SbNavigationBar",
	props: {
		title: {
			type: String,
			default: "",
		},
		placeholderSearch: {
			type: String,
			default: "Buscar un producto",
		},
		translucent: {
			type: Boolean,
			default: false,
		},
		paddingStart: {
			type: String,
			default: "16px",
		},
		paddingEnd: {
			type: String,
			default: "16px",
		},
	},
	components: {
		IonButton,
		IonButtons,
		IonToolbar,
		SbIcon,
		SbMainSearch,
		SbHeader,
		SbHeaderMobile,
		SbCart,
		SbLocationAddress,
	},
	setup() {
		const store = useStore()
		const router = useRouter()
		const route: any = useRoute()
		const isSeller = computed(() => store.getters["auth/getRole"] === 'Seller' ? true : false)
		const { getCart, clearCart } = useCart()
		const { presentAlertConfirm, presentAlert } = useAlert()
		const customer = computed(
			() => store.getters["auth/getCustomerConfiguredBySeller"]
		)

		const isVisibleDesktopNavigation = computed(() => {
			const pagesWithoutDesktopNavigation = [""]
			const is = pagesWithoutDesktopNavigation.includes(route.name)
			return !is
		})

		const isVisibleMobileNavigation = computed(() => {
			const pagesWithoutMobileNavigation = [
				"Login",
				"RegisterProcess",
				"ViewResultsSearch"
			]
			const is = pagesWithoutMobileNavigation.includes(route.name)
			return !is
		})

		const customerSelectedName = computed(() => {
			if (customer.value) {
				return `${customer.value?.name}`
			}
			return "Seleccionar cliente"
		})

		const navigationTitle = computed(() => {
			if (route.meta.navigationTitle === "ViewResultsSearch")
				return route.params.search
			if (route.name === "Category" && route.params.categoryName)
				return route.params.categoryName
			if (route.name === "Manufacturer" && route.params.manufacturerName)
			return route.params.manufacturerName
			if (route.name === "CustomProductList" && route.params.url_name)
				return route.params.url_name.toString().replaceAll("-", " ")

			return route.meta.navigationTitle
		})

		const handlerClearCart = () => {
			presentAlertConfirm({
				header: "¿Deseas eliminar este carrito?",
				textButtonConfirm: "Sí, eliminar",
				callbackConfirm: async () => {
					isLoading.value = true
					const deleted: boolean = await clearCart()

					if (deleted) {
						await getCart()
						isLoading.value = false
					} else {
						presentAlert({
							header: "No se pudo procesar la petición.",
							message:
								"Intentalo nuevamente, si el problema persiste contacta a soporte técnico.",
						})
						isLoading.value = false
					}
				},
			})
		}

		const handlerBack = () => {
			if (router.options.history.state.back) {
				router.go(-1)
			} else {
				router.push({
					name: "MarketplaceHome",
				})
			}
		}

		return {
			route,
			router,
			isSeller,
			navigationTitle,
			handlerBack,
			isVisibleDesktopNavigation,
			productsCountCart,
			handlerClearCart,
			customerSelectedName,
			isVisibleMobileNavigation,
		}
	},
})
