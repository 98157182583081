import { ref } from "vue"
import { isPlatform } from "@ionic/vue"
import { App } from "@capacitor/app"
import axios, { AxiosInstance } from "axios"
import { useAlert } from "../services/useAlert"

const api: AxiosInstance = axios.create({
	baseURL: process.env.VUE_APP_API_HOST_CART,
})

export const installedVersion = ref()

export function useCheckApp() {
	const { presentAlertConfirm } = useAlert()

	const isVersionUpdated = async (dataRequest: {
		platform: string
		version_number: string
	}) => {
		const path = `${process.env.VUE_APP_API_HOST}/compare_versions/`
		const response = await api.post(path, dataRequest)

		return response.data.ok ? true : false
	}

	const checkVersion = async () => {
		try {
			const info = await App.getInfo()
			installedVersion.value = info.version
	
			const showAlert = (url: string) => {
				presentAlertConfirm({
					header: "Información",
					subHeader: `Versión actual: ${installedVersion.value}`,
					message:
						"Hemos realizado cambios en nuestra aplicación, lo invitamos a actualizarla.",
					backdropDismiss: false,
					buttons: [
						{
							text: "Actualizar",
							role: "update",
							cssClass: "button__update",
							handler: () => {
								window.open(url, "_system")
							},
						},
					],
				})
			}
	
			if (isPlatform("ios")) {
				const isUpdated = await isVersionUpdated({
					platform: "Ios",
					version_number: installedVersion.value,
				})
	
				if (!isUpdated) {
					showAlert(
						"https://apps.apple.com/ni/app/superbeauty-app/id1631592836?l=en-GB"
					)
				}
	
				return
			}
	
			if (isPlatform("android")) {
				const isUpdated = await isVersionUpdated({
					platform: "Android",
					version_number: installedVersion.value,
				})
	
				if (!isUpdated) {
					showAlert(
						"https://play.google.com/store/apps/details?id=com.lezag.superbeauty&pli=1"
					)
				}
	
				return
			}
		} catch (error) {
			// eslint-disable-next-line
			console.log(error)
		}
	}

	return {
		checkVersion,
	}
}
