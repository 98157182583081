import { createRouter, createWebHistory } from "@ionic/vue-router"
import { RouteRecordRaw } from "vue-router"
import { query } from "vue-gtag"
import { Preferences } from "@capacitor/preferences"
import store from "../store/index"
import { useAuth, DataLoginWithEmailAndPassword } from "@/services/useAuth"
import { usePlatformVerify } from "@/services/usePlatformVerify"
import { cartRoutes } from "./cartRoutes"
import { authRoutes } from "./authRoutes"
import { manufacturerRoutes } from "./manufacturerRoutes"
import { categoryRoutes } from "./categoryRoutes"
import { purchaseRoutes } from "./purchaseRoutes"
import { orderRoutes } from "./orderRoutes"
import { profileRoutes } from "./profileRoutes"
import utils, { firstLetterToUpperCase } from "@/utils/index"
import { ROLES } from "@/constants/roles"

const routes: Array<RouteRecordRaw> = [
	...cartRoutes,
	...authRoutes,
	{
		path: "/",
		name: "Landing",
		meta: {
			pageTitle: "Bienvenido",
			navigationTitle: "Landing",
		},
		component: () => import("@/views/landing/SbPageHome.vue"),
		beforeEnter: async (to: any, from: any, next: any) => {
			const { isApp, isMobileWeb } = usePlatformVerify()
			const isLogged = await utils.isUserLogin()
			const isGuest = store.getters["auth/getRole"] === ROLES.GUEST

			if (isApp.value) {
				if ( isMobileWeb ) {
					next()
				} else {
					if (isLogged) {
						next({ path: "/home/" })
					} else {
						next({ path: "/login/" })
					}
				}
			} else {
				if ( isLogged && isGuest ) {
					next()
				} else {
					next({ path: "/home/" })
				}
			}
		},
	},
	{
		path: "/home/",
		redirect: "/home/marketplace/seller",
	},
	{
		path: "/home/",
		name: "Marketplace",
		meta: {
			requiresAuth: true,
		},
		component: () => import("@/views/SbPageMarketplace.vue"),
		children: [
			{
				path: "marketplace/customer",
				name: "MarketplaceHome",
				meta: {
					navigationTitle: "MarketplaceHome",
				},
				component: () => import("@/views/SbPageMarketplaceHome.vue"),
			},
			{
				path: "marketplace/seller",
				name: "MarketplaceHomeSeller",
				meta: {
					navigationTitle: "MarketplaceHomeSeller",
				},
				component: () => import("@/views/SbPageMarketplaceHome.vue"),
				beforeEnter: async (to: any, from: any, next: any) => {
					const isSeller = store.getters["auth/getRole"] === "Seller"

					if (isSeller) {
						next()
					} else {
						next({ name: "MarketplaceHome" })
					}
				},
			},
			{
				path: "search",
				name: "Search",
				meta: {
					navigationTitle: "Buscar",
					navigationSearch: true,
				},
				component: () => import("@/views/SbPageSearch.vue"),
			},
			{
				path: "support",
				name: "Support",
				component: () => import("@/views/SbPageSupport.vue"),
			},
			...profileRoutes,
		],
	},
	{
		path: "/view-results-search",
		name: "ViewResultsSearch",
		meta: {
			requiresAuth: true,
			navigationTitle: "ViewResultsSearch",
		},
		component: () => import("@/views/search/SbPageViewResultsSearch.vue"),
	},
	{
		path: "/product/:name/:productId/details",
		name: "ProductDetail",
		props: true,
		meta: {
			requiresAuth: true,
			pageTitle: "ProductDetail",
		},
		component: () => import("@/views/SbPageProductDetail.vue"),
	},
	{
		path: "/products/custom-list/:url_name",
		name: "CustomProductList",
		meta: {
			requiresAuth: true,
			pageTitle: ""
		},
		component: () => import("@/views/products/SbPageCustomProductList.vue")
	},
	{
		path: "/home/offers/full",
		name: "OffersFull",
		meta: {
			requiresAuth: true,
			navigationTitle: "Ofertas",
			navigationSearch: true,
			pageTitle: "Ofertas",
		},
		component: () => import("@/views/offers/SbPageOffersFull.vue"),
	},
	{
		path: "/home/new-products",
		name: "NewProducts",
		meta: {
			requiresAuth: true,
			navigationTitle: "Nuevos productos",
			pageTitle: "Nuevos productos",
		},
		component: () => import("@/views/new_products/SbPageNewProducts.vue"),
	},
	{
		path: "/home/bestsellers",
		name: "BestSellers",
		meta: {
			requiresAuth: true,
			navigationTitle: "Los más vendidos",
			pageTitle: "Los más vendidos",
		},
		component: () => import("@/views/best_sellers/SbPageBestSellers.vue"),
	},
	{
		path: "/home/combos/:title",
		name: "Combos",
		meta: {
			requiresAuth: true,
			navigationTitle: "Match perfecto",
			pageTitle: "Match perfectos",
		},
		component: () => import("@/views/combos/SbPageCombos.vue"),
	},
	...manufacturerRoutes,
	...categoryRoutes,
	...purchaseRoutes,
	...orderRoutes,
	{
		path: "/home/select-customer",
		name: "SelectCustomer",
		meta: {
			requiresAuth: true,
			navigationTitle: "Selecciona un cliente",
			visibleForGuest: false,
		},
		component: () => import("@/views/seller/SbPageSelectCustomerToCreateOrder.vue"),
		beforeEnter: async (to: any, from: any, next: any) => {
			const isSeller = store.getters["auth/getRole"] === "Seller"
			
			if (isSeller) {
				next()
			} else {
				next({ path: "/home/" })
			}
		},
	},
	{
		path: "/terms-conditions",
		name: "TermsAndConditions",
		meta: {
			navigationTitle: "Términos y condiciones",
			pageTitle: "Términos y condiciones",
		},
		component: () =>
			import("@/views/terms_and_conditions/SbPageTermsAndConditions.vue"),
	},
	{
		path: "/:pathMatch(.*)",
		redirect: "/",
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
})

const loginBackground = async () => {
	const { loginWithEmailAndPassword } = useAuth()
	const user = store.getters['auth/getUser']

	if ( !user ) {
		const userData: DataLoginWithEmailAndPassword = {
			email: process.env.VUE_APP_USER_GUEST_EMAIL,
			password: process.env.VUE_APP_USER_GUEST_PASSWORD
		}

		const response = await loginWithEmailAndPassword(userData)

		if (response.data.tokenReturn) {
			await store.dispatch(
				"auth/saveDataLogin",
				response.data
			)

			store.commit("auth/setIsLoggingIn", true)
			Preferences.set({
				key: "userLoginPreviously",
				value: JSON.stringify(true),
			})
		}
	}
}

router.beforeEach(async (to, from, next) => {
	await utils.isUserLogin()
	await loginBackground()

	if ( to.query.reference ) {
		store.commit("setReference", to.query.reference.toString())
	}

	if ( to.query.campaign ) {
		store.commit("setCampaign", to.query.campaign.toString())
	}

	if (to.matched.some(record => record.meta.requiresAuth)) {
		const customer = await utils.isThereACustomer()

		if ( to.matched.some(record => record.meta.visibleForGuest === false) ) {
			if ( store.getters["auth/getRole"] === ROLES.GUEST) {
				next({
					name: "Login",
				})

				return;
			}
		}

		// if (isLogged) {
			// Get data info in localStorage to set VUEX Store
			store.dispatch("order/getPaymentMethodName")
			store.dispatch("order/getPaymentMethodInfo")

			const isSeller = store.getters["auth/getRole"] === "Seller"
			if (isSeller) {
				if (to.meta.requiresCustomer) {
					if (customer) {
						next()
					} else {
						next({ name: "SelectCustomer" })
					}
				} else {
					next()
				}
			} else {
				next()
			}
		// } else {
			

		// 	next({
		// 		name: "Landing",
		// 	})
		// }
	} else {
		next() // make sure to always call next()!
	}
})

router.afterEach(async to => {
	/**
	 * Verificar si existe una referencia y/o campaña,
	 * si existen entonces estas debe estar a lo largo de
	 * todas las paginas del marketplace finalizar un pedido.
	 */

	const reference = store.getters['getReference']
	const campaign = store.getters['getCampaign']

	let currentPath = to.fullPath

	currentPath = utils.setQueryString(currentPath, { key: 'reference', value: reference })
	currentPath = utils.setQueryString(currentPath, { key: 'campaign', value: campaign })

	window.history.replaceState({}, '', currentPath)

	// =============================================================

	let pageTitle = ""
	const PAGE_TITLE_DEFAULT =
		"Superbeauty, la tienda de los negocios de belleza."

	if (to.name === "Manufacturer") {
		pageTitle = `${to.params.manufacturerName.toString()} | Compra por marca`
	} else if (to.name === "ProductDetail") {
		const name = to.params.name.toString().replaceAll("-", " ")
		pageTitle = `${name} | Superbeauty`
	} else if (to.name === "Category") {
		const name = to.params.categoryName.toString().replaceAll("-", " ")
		pageTitle = `${name} | Compra por categoría`
	} else {
		if (to.meta.pageTitle) {
			pageTitle = `${to.meta.pageTitle} | ${PAGE_TITLE_DEFAULT}`
		} else {
			pageTitle = PAGE_TITLE_DEFAULT
		}
	}

	pageTitle = firstLetterToUpperCase(pageTitle)
	;(document as any).title = pageTitle || PAGE_TITLE_DEFAULT

	query("event", "page_view", {
		page_title: pageTitle || PAGE_TITLE_DEFAULT,
		page_location: to.fullPath,
		page_path: to.path,
		language: "es_es",
	})
})

export default router
