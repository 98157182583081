import { ShippingAddress } from "@/types"
import { Preferences } from "@capacitor/preferences"

const state = () => ({
	shipping_address: null,
	paymentInfo: null,
	paymentMethod: "",
	comments: "",
})

const getters = {
	getShippingAddress(state: any) {
		return state.shipping_address
	},
	getPaymentMethod(state: any) {
		return state.paymentMethod
	},
	getPaymentInfo(state: any) {
		return state.paymentInfo
	},
	getComments(state: any) {
		return state.comments
	},
}

const actions = {
	async getPaymentMethodName({ state, commit }: any) {
		if (!state.paymentMethod) {
			const paymentMethodName = await Preferences.get({
				key: "paymentMethodName",
			})
			if (paymentMethodName.value) {
				commit("setPaymentMethod", paymentMethodName.value)
			}
		}
	},
	async getPaymentMethodInfo({ state, commit }: any) {
		if (!state.paymentInfo) {
			const paymentMethodInfo = await Preferences.get({
				key: "paymentMethodInfo",
			})

			if (paymentMethodInfo.value) {
				commit("setPaymentInfo", JSON.parse(paymentMethodInfo.value))
			}
		}
	},
}

const mutations = {
	setShippingAddress(state: any, shipping_address: ShippingAddress) {
		state.shipping_address = shipping_address
	},
	setPaymentMethod(state: any, payload: string) {
		Preferences.set({
			key: "paymentMethodName",
			value: payload,
		})

		state.paymentMethod = payload
	},
	setPaymentInfo(state: any, payload: any) {
		Preferences.set({
			key: "paymentMethodInfo",
			value: JSON.stringify(payload),
		})

		state.paymentInfo = payload
	},
	setComments(state: any, comments: any) {
		state.comments = comments
	},
	deletePaymentMethod(state: any) {
		state.paymentMethod = ""
	},
	deletePaymentInfo(state: any) {
		state.paymentInfo = null
	},
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
