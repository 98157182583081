import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import { IonicVue } from "@ionic/vue"
import VueGtag from "vue-gtag"

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css"

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css"
import "@ionic/vue/css/structure.css"
import "@ionic/vue/css/typography.css"

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css"
import "@ionic/vue/css/float-elements.css"
import "@ionic/vue/css/text-alignment.css"
import "@ionic/vue/css/text-transformation.css"
import "@ionic/vue/css/flex-utils.css"
import "@ionic/vue/css/display.css"

/* Theme variables */
import "./theme/variables.css"

// Customs Fonts, Styles
import "./assets/css/styles.css"

import store from "./store/index"

const app = createApp(App)
	.use(store)
	.use(IonicVue)
	.use(router)
	.use(
		VueGtag,
		{
			config: {
				id: process.env.GOOGLE_ANALYTICS_PROPERTY_ID || "GTM-W2HZJZB3",
				params: {
					send_page_view: false,
				},
			},
		},
		router
	)

router.isReady().then(() => {
	app.mount("#app")
})
