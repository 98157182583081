import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SbWelcomePopup = _resolveComponent("SbWelcomePopup")!
  const _component_SbNavigationBar = _resolveComponent("SbNavigationBar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_SbOnBoarding = _resolveComponent("SbOnBoarding")!
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!
  const _component_ion_app = _resolveComponent("ion-app")!

  return (_openBlock(), _createBlock(_component_ion_app, null, {
    default: _withCtx(() => [
      (_ctx.showPopup)
        ? (_openBlock(), _createBlock(_component_SbWelcomePopup, { key: 0 }))
        : _createCommentVNode("", true),
      _createVNode(_component_ion_page, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_header, { class: "ion-no-border" }, {
            default: _withCtx(() => [
              _createVNode(_component_SbNavigationBar)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_content, null, {
            default: _withCtx(() => [
              (_ctx.onBoardingShow)
                ? (_openBlock(), _createBlock(_component_SbOnBoarding, {
                    key: 0,
                    onOnBoardingClosed: _ctx.handlerOnBoardingClose
                  }, null, 8, ["onOnBoardingClosed"]))
                : _createCommentVNode("", true),
              _createVNode(_component_ion_router_outlet)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}