
import { computed, defineComponent } from "vue"
import { isPlatform, IonImg } from "@ionic/vue"
import SbButton from "@/components/SbButton.vue"
import { useRouter } from "vue-router"
import { useStore } from "vuex"
import SbMainSearchWeb from "../SbMainSearchWeb.vue"
import { useUserRole } from "@/services/useUserRole"
import SbIcon from "@/components/icons/SbIcon.vue"
import SbHeaderProfile from "./SbHeaderProfile.vue"
import SbCart from "../cart/SbCart.vue"
import SbPromotionalHeader from "./SbPromotionalHeader.vue"
import SbLocationAddress from "../SbLocationAddress.vue"

export default defineComponent({
	name: "SbHeader",
	components: {
		SbButton,
		SbMainSearchWeb,
		IonImg,
		SbIcon,
		SbHeaderProfile,
		SbCart,
		SbPromotionalHeader,
		SbLocationAddress
	},
	props: {
		title: String,
		searchBar: {
			type: Boolean,
			default: true,
		},
		iconCart: {
			type: Boolean,
			default: true,
		},
		backgroundColor: {
			type: String,
			default: "#fff",
		},
		typeLanding: {
			type: Boolean,
			default: false,
		},
	},
	setup() {
		const router = useRouter()
		const store = useStore()
		const user = computed(() => store.getters["auth/getUser"])
		const customer = computed(() => store.getters["auth/getCustomerConfiguredBySeller"])
		const { isSeller, isGuest } = useUserRole()

		const gotToCart = () => {
			router.push({
				name: "Cart",
			})
		}

		const customerSelectedName = computed(() => {
			if (customer.value) {
				return `${customer.value?.name}`
			}
			return "Seleccionar cliente"
		})

		return {
			store,
			isPlatform,
			router,
			gotToCart,
			user,
			isSeller,
			isGuest,
			customerSelectedName,
		}
	},
})
