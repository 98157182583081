import { createStore } from "vuex"
import authModule from "./modules/auth"
import orderModule from "./modules/order"
import productModule from "./modules/product"
import cartModule from "./modules/cart"

const store = createStore({
	state: {
		// query string in all app
		queryString: null,
		reference: '',
		campaign: '',
		// State Register
		resultLocationLat: null,
		resultLocationLng: null,
		resultLocationName: null,
		resultLocationPostalCode: null,
		// State Login
		loginInputChannel: "",
		appInfo: null,
	},
	getters: {
		getAppInfo(state: any) {
			return state.appInfo
		},
		getReference(state: any) {
			return state.reference
		},
		getCampaign(state: any) {
			return state.campaign
		}
	},
	mutations: {
		setReference(state, reference: string) {
			state.reference = reference
		},
		setCampaign(state, campaign: string) {
			state.campaign = campaign
		},
		setResultLocation(state, payload) {
			state.resultLocationLat = payload.lat
			state.resultLocationLng = payload.lng
			state.resultLocationName = payload.name
			state.resultLocationPostalCode = payload.postalCode
		},
		removeResultLocation(state) {
			state.resultLocationLat = null
			state.resultLocationLng = null
			state.resultLocationName = null
		},
		/**
		 * Guardar medio de envío de código de verificación de teléfono
		 * @param state state root
		 * @param { string } channel
		 */
		setLoginInputChannel(state, channel: string) {
			state.loginInputChannel = channel
		},
	},
	actions: {},
	modules: {
		auth: authModule,
		order: orderModule,
		product: productModule,
		cart: cartModule,
	},
})
export default store
