import jwt_decode from "jwt-decode"
import currency from 'currency.js'
import { DateTime } from "luxon";
import { Preferences} from "@capacitor/preferences";
import { useCookies } from "vue3-cookies";
import { useAuth } from "@/services/useAuth";
import { useTracking } from "@/services/useTracking";
import store from "../store/index";
const { cookies } = useCookies();
import { useAlert } from "@/services/useAlert";
import { ROLES } from "@/constants/roles";

/**
 * generatePasswordRand generate an password numeric, alphabetic o alphanumeric
 * @param length length of password
 * @param type type of password (numeric, alphabetic, rand)
 * @returns password string
 */
export const generatePasswordRand = (length: number, type = 'alphanumeric') => {
    let characters = ""
    if (type == 'numeric') { characters = "0123456789"; }
    if (type == 'alphabetic') { characters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"; }
    if (type == 'alphanumeric') { characters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"; }

    let pass = "0123456789".charAt(Math.floor(Math.random() * 10))
    const caractersInsertInStart = 1

    for (let i = 0; i < length - caractersInsertInStart; i++) {
        pass += characters.charAt(Math.floor(Math.random() * characters.length))
    }
    return pass;
}

/**
 * Verify if jwt token is alive or not, return `true` or `false`
 * @param token token jwt
 * @returns boolean
 */
export const tokenAlive = (token: any): boolean => {
    const t:any = jwt_decode(token)
    const copy = new Date();
    copy.setTime(t.exp * 1000)

    if(Date.now() > t.exp * 1000) return false;
    return true;
}

/**
 * formatPrice format a number in currency format
 * @param price number to format
 * @returns numeric chain. Example, $1.000
 */
export const formatPrice = (price: number): string => {
    return currency(price, { precision: 0, separator: '.'}).format()
}

/**
 * getTimeElapsed calculate time elapsed between two dates ( current date and previous date)
 * @param date previous date string
 * @returns object
 */
export const getTimeElapsed = ( date: string ) => {
    const nacimiento: any = new Date(date)
    const hoy: any = new Date()

    let timeElapsed = hoy - nacimiento
    const segs = 1000
    const mins = segs * 60
    const hours = mins * 60
    const days = hours * 24
    const months = days * 30.416666666666668
    const years = months * 12

    const yearsElapsed = Math.floor(timeElapsed / years);

    timeElapsed = timeElapsed - (yearsElapsed * years);
    const monthsElapsed = Math.floor(timeElapsed / months)

    timeElapsed = timeElapsed - (monthsElapsed * months);
    const daysElapsed = Math.floor(timeElapsed / days)

    timeElapsed = timeElapsed - (daysElapsed * days);
    const hoursElapsed = Math.floor(timeElapsed / hours)

    timeElapsed = timeElapsed - (hoursElapsed * hours);
    const minutesElapsed = Math.floor(timeElapsed / mins)

    timeElapsed = timeElapsed - (minutesElapsed * mins);
    const secondsElapsed = Math.floor(timeElapsed / segs)

    return {
        yearsElapsed,
        monthsElapsed,
        daysElapsed,
        hoursElapsed,
        minutesElapsed,
        secondsElapsed
    }
}

/**
 * Formatea una fecha en un formato específico.
 * @param {string} date - La fecha a formatear en formato ISO.
 * @param {object} [config] - Opciones de configuración (opcional).
 * @param {boolean} [config.year=false] - Indica si se incluye el año en el formato.
 * @param {string} [config.format="cccc ', ' dd 'de' LLLL"] - Formato de fecha personalizado.
 * @returns {string} La fecha formateada en el formato especificado.
 */
export const formatDate = (date: string, config?: any) => {
    const _config = { year: false, format: "cccc ', ' dd 'de' LLLL", ...config }
    // Si se proporciona la opción 'year' y es verdadera, se añade ' y' al formato.
    if (_config.year) {
        _config.format = `${_config.format} y`;
    }

    // Utiliza la biblioteca DateTime para formatear la fecha.
    const result = DateTime.fromISO(date, { zone: 'UTC', locale: 'es' }).toFormat(_config.format);
    
    return result.charAt(0).toUpperCase() + result.slice(1) // First letter to upper case
};

export const isDatePreviousToTheCurrentDate = (date: string) => {
    if ( date ) {
        const currentDate: any = DateTime.now();
        const dateToEvaluate: any = DateTime.fromISO(date, { zone: 'UTC', locale: 'es' });
        
        const dateToEvaluateDay = dateToEvaluate['c'].day
        const dateToEvaluateMonth = dateToEvaluate['c'].month
        const dateToEvaluateYear = dateToEvaluate['c'].year
        const currentDay = currentDate['c'].day
        const currentMonth = currentDate['c'].month
        const currentYear = currentDate['c'].year

        if ( dateToEvaluateYear > currentYear ) {
            return false
        }
    
        if ( dateToEvaluateMonth > currentMonth ) {
            return false
        } 

        if ( dateToEvaluateMonth == currentMonth ) {
            if (dateToEvaluateDay >= currentDay ) {
                return false
            }
            
            return true

        }
        
        return true        
    }
}

/**
 * If customer exist in store return true, otherwase return false
 * @returns {boolean}
 */
export const isThereACustomer = async (): Promise<boolean> => {
    if ( store.getters["auth/getCustomerConfiguredBySeller"] ) {
        return true
    }
    // If no exist in vuex store, then find it in local store
    const customer: any = await Preferences.get({ key: 'customer' })
    if ( customer.value ) {
        store.commit("auth/setCustomerConfiguredBySeller", JSON.parse(customer.value));
        return true
    }

    return false
}

export const isUserInStore = () => {
    return store.getters['auth/getUser'] ? true : false
}

export const setUserInStore = async () => {
    await store.dispatch('auth/getDataUserLoggedIn')
}

export const isUserLogin = async () => {
    const { getRefreshToken } = useAuth();

    if (!store.getters['auth/getToken']) {
        const token: any = await Preferences.get({ key: 'token' })
        const refresh_token: any = cookies.get('refreshToken')

        if (token.value) {
            if (tokenAlive(token.value)) {
                // Token is alive in localStorage
                // Set Token in Store
                store.commit("auth/setToken", token.value);
                if (refresh_token && refresh_token !== "undefined") {
                    store.commit("auth/setRefreshToken", refresh_token);
                }

                if (!isUserInStore()) {
                    await setUserInStore()
                }

                return true
            } else {
                if (refresh_token) {
                    try {
                        const response = await getRefreshToken({
                            token: token.value,
                            refreshToken: refresh_token,
                        });

                        if (response.status === 200) {
                            await store.dispatch('auth/saveDataLogin', {
                                'tokenReturn': response.data.tokenReturn,
                                'refreshToken': response.data.refreshToken,
                            })

                            if (!isUserInStore()) {
                                setUserInStore()
                            }

                            return true
                        } else {
                            // Loguot
                            store.dispatch("auth/userLogout");
                            return false;
                        }
                    } catch (error: any) {
                        // Loguot
                        store.dispatch("auth/userLogout");
                        return false;
                    }
                } else {
                    // Refresh token is dead
                    // Loguot
                    store.dispatch("auth/userLogout");
                    return false;
                }
            }
        } else {
            return false;
        }
    } else {
        if (store.getters["auth/getAliveToken"]) {
            // Token is alive in store
            return true;
        } else {
            try {
                const response = await getRefreshToken({
                    token: store.getters["auth/getToken"],
                    refreshToken: store.getters["auth/getRefreshToken"],
                });

                if (response.status === 200) {
                    await store.dispatch("auth/saveDataLogin", {
                        tokenReturn: response.data.tokenReturn,
                        refreshToken: response.data.refreshToken,
                    });
                    return true;
                }
            } catch (error: any) {
                // Loguot
                store.dispatch("auth/userLogout");
                return false;
            }
        }
    }
}

export const encodeURIParamsToRequest = (argumentsObject: any, othersParamsString = '') => {
    let params = '?'
    const argumentsKeys = Object.keys(argumentsObject)
    
    argumentsKeys.forEach((argumentKey) => {
        if ( argumentsObject[argumentKey] ) {
            params += `${argumentKey}=${argumentsObject[argumentKey]}&`
        }
    })

    // Configuration of modal filter params in case exists 
    return `${params}${othersParamsString}` 
}

export const userHasCoverage = async (cityId: string, stateId: string) => {
    if ( store.state.auth.isThereCoverage != null ) {
        return store.state.auth.isThereCoverage // true or false
    } else {
        const { verifyCoverage } = useTracking();
    
        try {
            const response = await verifyCoverage(cityId, stateId)
            store.commit('auth/setCoverage', response.data.data.coverage)
            return store.state.auth.isThereCoverage
        } catch ( error ) {
            store.commit('auth/setCoverage', false)
            return false
        }
    }
}

export const showAlertIfUserHasNoCoverage = async (): Promise<boolean> => {
    const { presentAlert } = useAlert()
    const currentUserRole = await store.getters['auth/getRole']

    if ( currentUserRole !== ROLES.GUEST ) {
        const customer = currentUserRole === 'Seller' ? store.getters["auth/getCustomerConfiguredBySeller"] : store.getters['auth/getUser'].customer

        if ( customer ) {
            const hasCoverage = await userHasCoverage(customer.cityId, customer.stateId)
            if ( !hasCoverage ) {
                await presentAlert({
                    header: 'Sin cobertura',
                    message: `Actualmente no tenemos cobertura para ${customer.city}.`,
                    handleDismiss: () => true
                })

                return false
            }

            return true
        }
    }

    return true
}

export const isValidEmail = (email: string): boolean => {
   const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
   return regex.test(email);
}

export function firstLetterToUpperCase(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function isVisibleInViewport(element?: HTMLElement) {
    if ( element ) {
        const distance = element?.getBoundingClientRect()
        return (
            distance?.top < (window.innerHeight || document.documentElement.clientHeight) 
        )
    }

    return false
}

export interface VerifyQuantityOption {
    productIsPresale?: boolean
    productVersionIsExcludedFromPresale?: boolean
    productMinQuantity?: number 
    productStock?: number
    quantityToAdd: number | string,
}
export const verifyIfQuantityIsValidToAdd = ({ quantityToAdd }: VerifyQuantityOption) => {
    // debugger
    const { presentAlert } = useAlert()

    
    // STEP 1: verify valid number
    if ( typeof quantityToAdd === 'string') {
        quantityToAdd = Number(quantityToAdd)

        if ( isNaN(quantityToAdd) ) {
            presentAlert({
                header: 'No es un número válido.',
                message: 'Debes ingresar un número valido.',
                buttons: [{ text: 'CORREGIR', role: 'cancel', }],
            })
    
            return false;
        }
    }

    // STEP 2: verify number different of ziro
    // if ( quantityToAdd === 0 ) {
    //     presentAlert({
    //         header: 'Cantidad mínima.',
    //         message: 'Debes ingresar una cantidad mínima.',
    //         buttons: [{ text: 'CORREGIR', role: 'cancel' }]
    //     })

    //     return false;
    // }
   

    // STEP 3: Verify that it is not a decimal or a float
    if ( !Number.isInteger(quantityToAdd) ) {
        presentAlert({
            header: 'No uses puntos ni comas.',
            message: 'Debes ingresar un número sin puntos ni comas.',
            buttons: [{ text: 'CORREGIR', role: 'cancel' }]
        })

        return false;
    }

    // STEP 4: Verify that it is greater that or equal to minimum quantity
    // if ( quantityToAdd < (productMinQuantity || 1) ) {
    //     presentAlert({
    //         header: 'Cantidad mínima no permitida.',
    //         message: `Debes ingresar mínimo ${productMinQuantity} unidades.`,
    //         buttons: [{ text: 'CORREGIR', role: 'cancel' }]
    //     })

    //     return false;
    // }

    // STEP 5: Verify that is a multiple of productMinQuantity
    // if ( (quantityToAdd % productMinQuantity) !== 0 ) {
    //     presentAlert({
    //         header: 'Cantidad no permitida.',
    //         message: `Debes ingresar unidades de ${productMinQuantity} en ${productMinQuantity}.`
    //     })

    //     return false;
    // }

    // STEP 6: Verify if its a presale product 
    // if ( productIsPresale ) {
    //     // If product isn´t excluded from presale, then user can add any quantity
    //     if ( !productVersionIsExcludedFromPresale ) {
    //         return true
    //     }

    //     // Is excluded from presale, then verify stock
    //     if ( quantityToAdd > productStock ) {
    //         presentAlert({
    //             header: ' ¡Atención!',
    //             subHeader: 'Stock máximo sobrepasado.',
    //             message: `Lo sentimos, solo quedan ${productStock} unidades disponibles.`,
    //             buttons: [{ text: 'CORREGIR', role: 'cancel' }]
    //         })
    
    //         return false;
    //     }
    // }

    // STEP 7: If isn´t presale, then verify stock
    // if ( Number(quantityToAdd) > productStock ) {
    //     presentAlert({
    //         header: ' ¡Atención!',
    //         subHeader: 'Stock máximo sobrepasado.',
    //         message: `Lo sentimos, solo quedan ${productStock} unidades disponibles.`,
    //         buttons: [{ text: 'CORREGIR', role: 'cancel' }]
    //     })

    //     return false;
    // }
    
    return true;
}

export const showAlertIfNoCustomerSelected = async (callbackConfirm: () => void, callbackCancel: () => void) => {
    const { presentAlertConfirm } = useAlert()
    const currentUserRole = await store.getters['auth/getRole']

    if ( currentUserRole !== 'Seller' ) {
        return true
    }

    const customer = await isThereACustomer()
    if ( customer ) {
        return true
    }

    presentAlertConfirm({
        header: 'Selecciona un cliente',
        message: 'Debes tener seleccionado un cliente para agregar productos. <br> ¿Deseas seleccionar un cliente?',
        textButtonConfirm: 'Seleccionar cliente',
        callbackConfirm,
        callbackCancel,
    })

    return false
}

function setQueryString(currentPath: string, newQueryString: { key: string, value: string }) {
    if ( newQueryString.value ) {
        if ( !currentPath.includes(newQueryString.key) ) {
            currentPath = `${currentPath}${currentPath.includes('?') ? '&' : '?'}${newQueryString.key}=${newQueryString.value}`
        }
    }

    return currentPath
}


export default {
    generatePasswordRand,
    tokenAlive,
    formatPrice,
    getTimeElapsed,
    formatDate,
    isDatePreviousToTheCurrentDate,
    isThereACustomer,
    isUserLogin,
    encodeURIParamsToRequest,
    userHasCoverage,
    showAlertIfUserHasNoCoverage,
    isValidEmail,
    firstLetterToUpperCase,
    isVisibleInViewport,
    verifyIfQuantityIsValidToAdd,
    showAlertIfNoCustomerSelected,
    setQueryString
}