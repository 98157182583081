
import { defineComponent, onMounted, ref } from "vue"

export default defineComponent({
	name: "SbIcon",
	props: {
		icon: {
			type: String,
			default: "",
		},
	},
	setup(props) {
		const img = ref(null)
		const getImage = async () => {
			try {
				img.value = await require("@/assets/icons/" + props.icon)
			} catch (e: any) {
				img.value = null
			}
		}

		onMounted(() => {
			getImage()
		})

		return {
			img,
		}
	},
})
