
import { defineComponent, onMounted } from "vue"
import SbModal from "@/components/SbModal.vue"
import { useMarketing } from "@/services/useMarketing"

export default defineComponent({
	name: "SbWelcomePopup",
	setup() {
		const { getBannerByPositionName, banners } = useMarketing()

		onMounted(async () => {
			getBannerByPositionName("Banner popup")
		})

		return {
			banners,
		}
	},
	components: { SbModal },
})
