import { InterceptorResponseStrategy } from "@/types";
import utils from "@/utils";
import { AxiosError } from "axios";

export class UnauthorizedResponseInterceptor implements InterceptorResponseStrategy {
    applyInterceptor(error: AxiosError): any {
        if ( error.response?.status === 401 ) {
            this.retryRequestWithNewToken(error)
        }
    }

    private async retryRequestWithNewToken (error: any) {
        const isLogged = await utils.isUserLogin()
        let errorSolutionMessage = ''

        if ( isLogged ) {
            errorSolutionMessage = 'New token generated'
        }

        error.errorSolutionMessage = errorSolutionMessage
        return Promise.reject(error);
    }
}