
import { defineComponent, onMounted, ref } from "vue"
import { Swiper, SwiperSlide } from "swiper/vue"
import { Autoplay, Keyboard, Pagination, Controller, Navigation } from "swiper"
import SbButton from "@/components/SbButton.vue"

import "swiper/css"
import "swiper/css/autoplay"
import "swiper/css/keyboard"
import "swiper/css/pagination"
import "swiper/css/navigation"
import "@ionic/vue/css/ionic-swiper.css"

export default defineComponent({
	name: "SbOnBoarding",
	components: {
		Swiper,
		SwiperSlide,
		SbButton,
	},
	setup(props, { emit }) {
		const swiperInstance: any = ref(null)

		const getInstanceSwiper = (swiper: any) => {
			swiperInstance.value = swiper
		}
		const nextSlide = () => {
			if (swiperInstance.value) {
				if (
					swiperInstance.value.activeIndex + 1 ==
					swiperInstance.value.slides.length
				) {
					dismissOnboarding()
				}
			}
			// eslint-disable-next-line
			;(document as any).querySelector(".swiper-button-next").click()
		}

		const dismissOnboarding = () => {
			emit("onBoardingClosed")
		}

		onMounted(() => {
			// eslint-disable-next-line
			;(document as any).querySelector(
				".swiper-button-prev"
			).style.display = "none"
			// eslint-disable-next-line
			;(document as any).querySelector(
				".swiper-button-next"
			).style.display = "none"
		})

		return {
			modules: [Autoplay, Keyboard, Pagination, Controller, Navigation],
			nextSlide,
			dismissOnboarding,
			getInstanceSwiper,
		}
	},
})
