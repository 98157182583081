
import { computed, defineComponent, PropType } from "vue"
import { IonButton } from "@ionic/vue"

const __default__ = defineComponent({
	name: "SbButton",
	props: {
		mode: {
			type: String as PropType<"custom" | "fill" | "fill-white" | "fill-black">,
			default: "custom",
		},
		size: {
			type: String,
			default: "medium",
		},
		width: {
			type: String,
			default: "100%",
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		shadow: {
			type: Boolean,
			default: false,
		},
		borderColor: {
			type: String,
			default: "#000000",
		},
		borderWidth: {
			type: String,
			default: "2px",
		},
		borderRadius: {
			type: String,
			default: "8px",
		},
		outline: {
			type: Boolean,
			default: false,
		},
		backgroundColor: {
			type: String,
			default: "",
		},
		textColor: {
			type: String,
			default: "",
		},
	},
	components: {
		IonButton,
	},
	setup(props) {
		const cssProps = computed(() => ({
			"--width-button": props.width,
			"--border-radius": props.borderRadius,
			"--border-color": props.borderColor,
			"--box-shadow": props.shadow ? "initial" : "none",
			"--border-width": props.borderWidth,
			"--padding-start": 0,
			"--padding-end": 0,
		}))
		return {
			cssProps,
		}
	},
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "485967ec": (_ctx.width),
  "3581dfce": (_ctx.backgroundColor),
  "a4a0eb0c": (_ctx.textColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__