
import { defineComponent, ref, onMounted } from "vue"
import { useStore } from "vuex"
import { useRouter } from "vue-router"
import { IonRow, IonCol } from "@ionic/vue"
import SbIcon from "../icons/SbIcon.vue"
import { useUserRole } from "../../services/useUserRole"
import { installedVersion } from "../../services/useCheckApp"

export default defineComponent({
	name: "SbProfileOptions",
	components: {
		IonRow,
		IonCol,
		SbIcon,
	},
	setup() {
		const router = useRouter()
		const store = useStore()
		const user: any = ref(store.getters["auth/getUser"])
		const initialsOfNames = ref("")
		const { isSeller, isAdminCustomer } = useUserRole()

		const logout = async () => {
			store.dispatch("auth/userLogout")
			await router.push({ name: "Landing" })
			window.location.reload()
		}

		onMounted(async () => {
			user.value = await store.getters["auth/getUser"]
			initialsOfNames.value =
				user.value.name.substring(0, 1) +
				user.value.last_name.substring(0, 1)
		})

		return {
			user,
			router,
			logout,
			initialsOfNames,
			isSeller,
			isAdminCustomer,
			installedVersion,
		}
	},
})
