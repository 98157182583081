
import { defineComponent, computed, ref, watch, onMounted } from "vue"
import { promotionalHeader, useMarketing } from "@/services/useMarketing"
import SbIcon from "../icons/SbIcon.vue"

export default defineComponent({
	name: "SbPromotionalHeader",
	components: {
		SbIcon,
	},
	setup() {
		const { getPromotionalHeader } = useMarketing()
		const showPromotionalHeader = ref(
			localStorage.getItem("showPromotionalHeader") === "true"
		)
		const design = computed(() => {
			return {
				backgroundTimer: promotionalHeader.value?.design.timerBg,
				timerTitle: promotionalHeader.value?.title,
				timerText: promotionalHeader.value?.design.itemTimeText,
				timerBackground: promotionalHeader.value?.design.itemTimeBg,
				tagText: promotionalHeader.value?.design.tagText,
				tagBackground: promotionalHeader.value?.design.tagBg,
			}
		})
		const timer = ref({
			days: 0,
			hours: 0,
			minutes: 0,
			seconds: 0,
			active: false,
		})
		const defineTimer = (date: any) => {
			if (!date)
				return {
					days: 0,
					hours: 0,
					minutes: 0,
					seconds: 0,
					active: false,
				}
			const endDate = new Date(date)
			const now = new Date().getTime()
			const distance = endDate.getTime() - now
			const days = Math.floor(distance / (1000 * 60 * 60 * 24))
			const hours = Math.floor(
				(distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
			)
			const minutes = Math.floor(
				(distance % (1000 * 60 * 60)) / (1000 * 60)
			)
			const seconds = Math.floor((distance % (1000 * 60)) / 1000)

			if (promotionalHeader.value?.timer === false) {
				return {
					days: 0,
					hours: 0,
					minutes: 0,
					seconds: 0,
					active: false,
				}
			}
			const active = distance > 0

			return {
				days,
				hours,
				minutes,
				seconds,
				active,
			}
		}
		const updateTimer = () => {
			timer.value = defineTimer(promotionalHeader.value?.end_date)
		}
		watch(promotionalHeader, () => {
			updateTimer()
		})

		setInterval(updateTimer, 1000)

		onMounted(() => {
			getPromotionalHeader()
			localStorage.setItem("showPromotionalHeader", "true")
			updateTimer()
		})

		const closePromotionalHeader = () => {
			showPromotionalHeader.value = false
			localStorage.setItem("showPromotionalHeader", "false")
		}

		return {
			design,
			timer,
			showPromotionalHeader,
			closePromotionalHeader,
		}
	},
})
