import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-557dc6cc"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "ion-hide-sm-up"
}
const _hoisted_2 = { class: "modal_popup_welcome" }
const _hoisted_3 = { class: "modal_popup_welcome_container" }
const _hoisted_4 = { class: "modal_popup_welcome_close" }
const _hoisted_5 = { class: "modal_popup_welcome_wrapper" }
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 1,
  class: "ion-hide-sm-down"
}
const _hoisted_8 = { class: "modal_popup_welcome" }
const _hoisted_9 = { class: "modal_popup_welcome_container" }
const _hoisted_10 = { class: "modal_popup_welcome_close" }
const _hoisted_11 = { class: "modal_popup_welcome_wrapper" }
const _hoisted_12 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_backdrop = _resolveComponent("ion-backdrop")!
  const _component_SbIcon = _resolveComponent("SbIcon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.showPopup)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        (_ctx.popupMobile)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_ion_backdrop, {
                class: "ion-hide-sm-up",
                style: _normalizeStyle({ display: _ctx.showPopup ? 'block' : 'none' })
              }, null, 8, ["style"]),
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("span", _hoisted_4, [
                    _createVNode(_component_SbIcon, { icon: "close.svg" })
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    (_ctx.popupMobile?.app_link)
                      ? (_openBlock(), _createBlock(_component_router_link, {
                          key: 0,
                          class: "modal_popup_welcome__link",
                          to: `${_ctx.popupMobile?.app_link}`
                        }, null, 8, ["to"]))
                      : _createCommentVNode("", true),
                    _createElementVNode("img", {
                      class: "modal_popup_welcome__image",
                      src: _ctx.popupMobile?.medium
                    }, null, 8, _hoisted_6)
                  ])
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.popup)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createVNode(_component_ion_backdrop, {
                style: _normalizeStyle({ display: _ctx.showPopup ? 'block' : 'none' })
              }, null, 8, ["style"]),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("span", _hoisted_10, [
                    _createVNode(_component_SbIcon, { icon: "close.svg" })
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    (_ctx.popup?.app_link)
                      ? (_openBlock(), _createBlock(_component_router_link, {
                          key: 0,
                          class: "modal_popup_welcome__link",
                          to: `${_ctx.popup?.app_link}`
                        }, null, 8, ["to"]))
                      : _createCommentVNode("", true),
                    _createElementVNode("img", {
                      class: "modal_popup_welcome__image",
                      src: _ctx.popup?.medium
                    }, null, 8, _hoisted_12)
                  ])
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ], 64))
    : _createCommentVNode("", true)
}