
import { defineComponent, ref } from "vue"
import { useStore } from "vuex"
import { IonPopover, IonContent, IonGrid, IonRow } from "@ionic/vue"
import SbIcon from "../icons/SbIcon.vue"
import SbProfileOptions from "../profile/SbProfileOptions.vue"
import { useRouter } from "vue-router"

export default defineComponent({
	name: "SbHeaderProfile",
	setup() {
		const store = useStore()
		const user = store.getters["auth/getUser"]
		const popoverOpen = ref(false)
		const router = useRouter()

		const openPopover = (state: boolean) => (popoverOpen.value = state)

		return {
			router,
			user,
			openPopover,
			popoverOpen,
		}
	},
	components: {
		IonPopover,
		IonContent,
		SbIcon,
		SbProfileOptions,
		IonGrid,
		IonRow,
	},
})
