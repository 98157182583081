import axios, { AxiosInstance } from "axios"
import store from "../store/index"

const apiTerm: AxiosInstance = axios.create({
	baseURL: process.env.VUE_APP_API_HOST_MARKETING,
})

apiTerm.interceptors.request.use((config: any) => {
	const token = store.getters["auth/getToken"]
	const warehouseId = store.getters["auth/getUser"].warehouseId

	config.headers.common["token"] = token
	config.params = { ...config.params, warehouse: warehouseId }
	return config
})

export function useTerm() {
	const saveTerm = (term: string) => {
		return apiTerm.post("/searchTerms/add", {
			searchTerm: term,
		})
	}
	const getTerms = () => {
		return apiTerm.get("/searchterms/list")
	}
	const getTermsTop = () => {
		return apiTerm.get("/searchterms/top")
	}
	const getTermsUser = () => {
		return apiTerm.get("/searchterms/list-user")
	}
	const getTermsUserTop = () => {
		return apiTerm.get("/searchterms/top-user")
	}

	return {
		saveTerm,
		getTerms,
		getTermsTop,
		getTermsUser,
		getTermsUserTop,
	}
}
